<template>
  <base-dialog
    :visible.sync="visibleState"
    :showFooter="false"
    fullscreen
    top="0vh"
    width="90%"
    custom-class="paymentOrderDialog"
    title="融资申请详情">
    <access-documents-make :isBusinessId="businessId" lookType="details"></access-documents-make>
    <template slot="footer">
      <base-button label="关 闭" @click="visibleState = false"></base-button>
    </template>
  </base-dialog>
</template>
<script>
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import accessDocumentsMake from '../access-documents-make.vue'
export default {
  components: { BaseDialog, BaseButton, accessDocumentsMake },
  props: {
    visible: Boolean,
    businessId: String
  },
  data () {
    return {}
  },
  computed: {
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        return this.$emit('update:visible', val)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog /deep/.paymentOrderDialog {
   margin-top: 8px !important;
}
</style>
