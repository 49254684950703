// 稳时付----准入文件制作
<template>
  <div class="accessMake">
    <backtitle></backtitle>
    <div class="mainContent" id="mainContent" ref="mainContent">
      <div data-title="融资申请信息" class="modelBox">
        <text-title index='01' label="融资申请信息"></text-title>
        <financing-application-info :financingApplicationData='financingApplicationData' ref="financingApplicationData"></financing-application-info>
      </div>
      <div data-title="标的资产" class="modelBox">
        <text-title index='02' label="标的资产"></text-title>
        <asset-information ref="assetInformation" :showRequired='showRequired'></asset-information>
      </div>
      <div data-title="担保信息" class="modelBox">
        <text-title index='03' label="担保信息"></text-title>
        <div>
          <personal-guarantee ref="personalGuarantee"></personal-guarantee>
          <enterprise-guarantee ref="enterpriseGuarantee"></enterprise-guarantee>
        </div>
      </div>
      <div data-title="备案条款" class="modelBox">
        <text-title index='04' label="备案条款"></text-title>
        <filing-terms ref="filingTerms"></filing-terms>
      </div>
      <div data-title="问题汇总" class="modelBox">
        <text-title index='05' label="问题汇总"></text-title>
        <div class="desc">
          <el-input
            type="textarea"
            placeholder="请输入"
            v-model="financingApplicationData.problemSummary"
            maxlength="2000"
            show-word-limit
            rows="5"
          ></el-input>
        </div>
      </div>
      <div data-title="提示" class="modelBox">
        <text-title index='06' label="提示"></text-title>
        <div class="desc">
          <el-input
            type="textarea"
            placeholder="请输入"
            v-model="financingApplicationData.tips"
            maxlength="2000"
            show-word-limit
            rows="5"
          ></el-input>
        </div>
      </div>
      <div data-title="附件" class="modelBox">
        <text-title index='07' label="附件" :required="true"></text-title>
        <accessories ref="accessories"></accessories>
      </div>
      <div data-title="文件生成与下载" class="modelBox">
        <text-title index='08' label="文件生成与下载"></text-title>
        <file-generation :fileMakeData='fileMakeData' ref="fileMakeData"></file-generation>
      </div>
    </div>
    <div class="footer">
      <base-button label="保 存" @click="saveData('save')"></base-button>
      <base-button label="提 交" @click="saveData('submit')"></base-button>
      <base-button label="关 闭" type="default" @click="close"></base-button>
    </div>
  </div>
</template>
<script>
import TextTitle from '@/components/packages/text-title/text-title.vue'
import Backtitle from '../../components/backtitle.vue'
import baseButton from '@/components/common/button/base-button/base-button.vue'
// import BaseForm from '@/components/common/base-form/base-form'
import FinancingApplicationInfo from '../components/financing-application-info.vue'
import AssetInformation from '../components/asset-information.vue'
import Accessories from '../components/accessories.vue'
import fileGeneration from '../components/file-generation-and-download.vue'
import FilingTerms from '../components/filing-terms.vue'
import PersonalGuarantee from '../components/personal-guarantee.vue'
import EnterpriseGuarantee from '../components/enterprise-guarantee.vue'
import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
import { PSBC_KEY_ID } from '@/config/constant'

export default {
  components: {
    TextTitle,
    Backtitle,
    baseButton,
    AssetInformation,
    FinancingApplicationInfo,
    Accessories,
    fileGeneration,
    PersonalGuarantee,
    EnterpriseGuarantee,
    FilingTerms
  },
  props: {
    baseData: Object
  },
  data () {
    return {
      showRequired: false,
      financingApplicationData: {}, // 融资申请信息
      fileMakeData: {}, // 文件制作数据
      problemSummary: '', // 问题汇总
      tipsDescription: '', // 提示信息
      expectDueDiligence: {}, // 尽调数据
      passArr: [],
      savePassArr: [], // 所有校验结果
      businessId: ''
    }
  },
  watch: {
    financingApplicationData: {
      handler (val) {
        // 判断当前融资产品是稳时付
        // val.financeProductType === 'STEADY_PAYMENT'
        if (val.capitalSideId === PSBC_KEY_ID) {
          this.showRequired = true
        } else {
          this.showRequired = false
        }
      },
      deep: true
    },
    baseData: {
      handler: function (val) {
        if (val) {
          // 申请信息
          this.financingApplicationData = val
          // 提示
          // this.tipsDescription = val?.tips
          // 问题汇总
          // this.problemSummary = val?.problemSummary
          // 生成文件
          this.fileMakeData = val.fileListInfo ? val.fileListInfo : {}
        }
      },
      deep: true
    }
  },
  computed: {
    subArr () {
      return new Map([['save', '保存成功'], ['submit', '提交成功']])
    },
    errArr () {
      return new Map([['save', '保存失败'], ['submit', '提交失败']])
    }
  },
  created () {
    this.businessId = this.$route.query.businessId
  },
  methods: {
    verificationAll (subType) {
      this.savePassArr = []
      // 融资申请信息
      if (!this.$refs.financingApplicationData.validateFinancingApplication()) {
        this.savePassArr.push({ isPass: false, warningTip: '请完善融资申请信息', module: 0 })
      }
      this.vailAssetInfoTable()
      this.vailProjectInfo()
      // if (!this.$refs.personalGuarantee.personalData.length > 0) {
      //   this.savePassArr.push({ isPass: false, warningTip: '请添加个人担保', module: 2 })
      // }
      // if (!this.$refs.enterpriseGuarantee.enterpriseData.length > 0) {
      //   this.savePassArr.push({ isPass: false, warningTip: '请添加企业担保', module: 2 })
      // }
      this.vailAccessories()
      if (subType === 'submit') {
        // 文件生成
        if (!this.$refs.fileMakeData.valiFile()) {
          this.savePassArr.push({ isPass: false, warningTip: '请生成或上传文件', module: 7 })
        }
      }
      const tip = this.savePassArr.find(item => item.isPass === false)
      if (tip) {
        this.warning(tip.warningTip)
        this.$parent.scrollFool(tip.module)
      } else {

      }
      return tip
    },
    // 标的资产校验
    vailAssetInfoTable () {
      let pass = true
      if (this.$refs.assetInformation.assetInfoData.length > 0) {
        this.$refs.assetInformation.assetInfoData.forEach(item => {
          if (!item.finContractId || !item.finContractName || !item.pledgeValue || !item.relLoanAmount || (this.showRequired && !item.easNo)) {
            pass = false
          }
        })
      } else {
        pass = false
      }
      if (!pass) {
        this.savePassArr.push({ isPass: false, warningTip: '请完善标的资产资产信息', module: 1 })
      }
    },
    // 标的资产项目信息
    vailProjectInfo () {
      let pass = true
      if (this.$refs.assetInformation.projectInfoData.length > 0) {
        this.$refs.assetInformation.projectInfoData.forEach(item => {
          if (!item.shareholdingRatio || !item.controllingEntity) {
            pass = false
          }
        })
      } else {
        pass = false
      }
      if (!pass) {
        this.savePassArr.push({ isPass: false, warningTip: '请完善标的资产项目信息', module: 1 })
      }
    },
    // 附件校验
    vailAccessories () {
      // let pass = true
      // const arr = this.$refs.accessories.accessoriesData
      // if (arr.length > 0) {
      //   for (const obj of arr) {
      //     if (obj.isProvide !== '1') {
      //       if (!obj.noReasonProvided) {
      //         this.savePassArr.push({ isPass: false, warningTip: '请输入未收集原因', module: 6 })
      //       }
      //     }
      //   }
      // } else {
      //   pass = false
      // }
      // if (!pass) {
      //   this.savePassArr.push({ isPass: false, warningTip: '请选择附件', module: 6 })
      // }
    },
    // 设置基本信息质押率
    chanegPledgeRate (data) {
      this.$set(this.financingApplicationData, 'totalPledgeRate', data)
    },
    // 调提交方法
    async  getfn (subType) {
      this.passAr = []
      await this.saveBaseInfo(subType)
      await this.saveOrUpdateContract(subType)
      await this.saveFilingTerms(subType)
      await this.saveAdmittanceFile(subType)
      // 如果是生成文件,校验后不提示
      if (subType === 'generate') {
        // 刷新标的资产信息防止删除列表时找不到keyId
        this.$refs.assetInformation.getAssetsInfo()
        return
      }
      const isPass = this.passArr.every(item => item === true)
      if (isPass) {
        this.success(this.subArr.get(subType))
        if (subType === 'submit') {
          this.$router.back()
        } else {
          // 刷新标的资产信息防止删除列表时找不到keyId
          this.$refs.assetInformation.getAssetsInfo()
        }
      } else {
        this.warning(this.errArr.get(subType))
      }
    },

    // 保存
    saveData (subType) {
      this.savePassArr = []
      if (subType === 'submit') {
        if (!this.verificationAll(subType)) {
          this.getfn(subType)
        }
      } else if (subType === 'save') {
        this.getfn(subType)
      }
    },

    // 备案条款保存
    async  saveFilingTerms (subType) {
      const data = { data: this.$refs.filingTerms.filingTermsData, businessId: this.businessId }
      let res = {}
      if (subType === 'save') {
        res = await financeAdmittanceApi.saveFilingTerms(data)
      } else if (subType === 'submit') {
        res = await financeAdmittanceApi.submitFilingTerms(data)
      } else if (subType === 'generate') {
        res = await financeAdmittanceApi.submitFilingTerms(data)
      }
      return this.passArr.push(res.data)
    },
    // 保存附件信息
    async  saveAdmittanceFile (subType) {
      const data = { finFileListInfos: this.$refs.accessories.accessoriesData, saveFlag: subType === 'save' ? '0' : '1' }
      const res = await financeAdmittanceApi.saveAdmittanceFile(data)
      console.log(res.data, '保存附件信息')
      return this.passArr.push(res.data)
    },
    // 保存基本信息
    async  saveBaseInfo (subType) {
      const data = { ...this.$refs.financingApplicationData.currentData, fileListInfo: this.$refs.fileMakeData.fileFormData }
      data.tips = this.financingApplicationData.tips
      data.problemSummary = this.financingApplicationData.problemSummary
      delete data.businessId
      let res = {}
      if (subType === 'save') {
        res = await financeAdmittanceApi.saveAdmittance(data)
      } else if (subType === 'submit') {
        data.submitType = '0'
        res = await financeAdmittanceApi.submitAdmittance(data)
      } else if (subType === 'generate') {
        data.submitType = '1'
        res = await financeAdmittanceApi.submitAdmittance(data)
      }
      console.log(res.data, '保存基本信息')
      return this.passArr.push(res.data)
    },
    // 保存标的资产信息
    async saveOrUpdateContract (subType) {
      const data = {
        businessId: this.businessId,
        finContractInfoList: this.$refs.assetInformation.assetInfoData,
        finProjectInfoList: this.$refs.assetInformation.projectInfoData
      }
      let res = {}
      if (subType === 'save') {
        res = await financeAdmittanceApi.saveOrUpdateContract(data)
      } else if (subType === 'submit') {
        data.submitType = '0'
        res = await financeAdmittanceApi.submitOrUpdateContract(data)
      } else if (subType === 'generate') {
        data.submitType = '1'
        res = await financeAdmittanceApi.submitOrUpdateContract(data)
      }
      console.log(res.data, '保存标的资产信息')
      return this.passArr.push(res.data)
    },
    close () {
      this.$router.back()
    }
  }
}
</script>
<style lang="scss" scoped>
.accessMake {
  padding: 0px 10px;
  height: 100%;
  background: #f9f9f6;
  .mainContent {
    background: #fff;
    height: calc(100% - 105px);
    overflow-y: scroll;
  }
  .footer {
    text-align: center;
    margin: 0px 0 10px;
    padding: 14px 0;
    box-shadow: 0px 0px 13px 2px rgba(#4A7CF0, 0.2);
    background: #ffffff;
  }
}
.desc{
  padding: 10px;
}
</style>
