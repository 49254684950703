// 备案条款
<template>
  <div class="filingTerms">
    <div class="addBtn">
      <div class="addbutton" @click="addInfo">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <base-table
      :columns="filingTermsColumn"
      :tableAttrs="{
        data: filingTermsData,
        stripe: true,
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >
      <!-- 审核结果 -->
      <template slot="approveResult" slot-scope="scope">
        <el-radio-group v-model="scope.row.approveResult">
          <el-radio :label="'0'">是</el-radio>
          <el-radio :label="'1'">否</el-radio>
        </el-radio-group>
      </template>
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button
        v-if="scope.row.resource==='0'"
          content="删除"
          icon="iconfont iconshanchu1"
          @click="dellInfo(scope.$index)"
        />
      </template>
       <!-- 备案条款 -->
      <template slot="filingTerms" slot-scope="scope">
        <div v-if="scope.row.add">
          <el-input
            v-model="scope.row.filingTerms"
            placeholder="请输入备案条款"
            maxlength="20"
          ></el-input>
        </div>
        <div v-else>{{ scope.row.filingTerms }}</div>
      </template>
    </base-table>
  </div>
</template>
<script>
import baseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { filingTermsTable } from '../utils/make-config'
import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
export default {
  components: { baseTable, IconButton },
  data () {
    return {
      filingTermsData: [] // 备案条款数据
    }
  },
  computed: {
    filingTermsColumn () {
      return filingTermsTable(this)
    }
  },
  mounted () {
    this.getTableData()
  },
  methods: {
    // 获取表格初始化数据
    getTableData () {
      financeAdmittanceApi.getAdmittanceFinling({ businessId: this.$route.query.businessId }).then(res => {
        this.filingTermsData = res.data
      })
    },
    addInfo () {
      this.filingTermsData.push({ add: true, resource: '0', businessId: this.$route.query.businessId })
    },
    dellInfo (index) {
      this.filingTermsData.splice(index, 1)
    }
  }
}
</script>
<style lang="scss" scoped>
.filingTerms {
  padding: 10px;
}
.addBtn {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  .addbutton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
</style>
