// 准入文件制作-中间层
<template>
  <div>
    <!-- 稳享付制作 -->
    <stable-enjoy-payment v-if="showType === '产值贷' && type === 'deit'" :baseData='baseData'></stable-enjoy-payment>
    <!-- <stable-enjoy-payment v-if="showType === '稳享付' && type === 'deit'" :baseData='baseData'/> -->
    <!-- 稳时付制作 -->
    <stable-time-payment v-if="showType === '稳时付' && type === 'deit'" :baseData='baseData'/>
    <!-- 稳享付详情 -->
    <stable-enjoy-details v-if="showType === '产值贷' &&type === 'details'" :baseData='baseData' :isBusinessId="isBusinessId"></stable-enjoy-details>
    <!-- <stable-enjoy-details v-if="showType === '稳享付' &&type === 'details'" :baseData='baseData' :isBusinessId="isBusinessId"/> -->
    <!-- 稳时付详情 -->
    <stable-time-details v-if="showType === '稳时付' && type === 'details'" :baseData='baseData' :isBusinessId="isBusinessId"/>
    <!-- 滚动楼层 -->
    <scroll-fool ref="scrollFool" v-if="!isBusinessId"/>
  </div>
</template>
<script>
import StableEnjoyPayment from './products/stable-enjoy-payment.vue'
import StableTimePayment from './products/stable-time-payment.vue'
import ScrollFool from '@/components/packages/scroll-fool/scroll-fool.vue'
import StableEnjoyDetails from './products/stable-enjoy-details.vue'
import StableTimeDetails from './products/stable-time-details.vue'
import Storage from '@/utils/storage'
import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'

export default {
  components: { StableEnjoyPayment, StableTimePayment, ScrollFool, StableEnjoyDetails, StableTimeDetails },
  props: {
    isBusinessId: String,
    lookType: String
  },
  data () {
    return {
      businessId: '', // 业务id
      baseData: {}, // 基础数据
      scrollData: [{ label: '融资信息', id: 0 }]
    }
  },
  computed: {
    showType () {
      return Storage.getLocal('admittanceInfo')?.finaaceProductName
    },
    type () {
      return this.$route.query.type || this.lookType
    }
  },
  created () {
    this.businessId = this.$route.query.businessId
    console.log(this.showType)
    console.log(this.type)
  },
  watch: {
    businessId (val) {
      if (val) {
        this.getBaseInfo()
      } else if (this.isBusinessId) {
        this.getBaseInfo()
      }
    }
  },
  methods: {
    // 获取基本信息
    getBaseInfo () {
      financeAdmittanceApi.baseAdminttanceInfo({ businessId: this.businessId || this.isBusinessId }).then(res => {
        if (res.data) {
          this.baseData = res.data
        }
      })
    },
    // 调用楼层的滚动方法
    scrollFool (data) {
      this.$refs.scrollFool.touchactive(data)
    }
  }
}
</script>
