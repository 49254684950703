// 稳享付----准入文件制作
<template>
  <div class="accessMake">
    <backtitle v-if="!isBusinessId"></backtitle>
    <div class="mainContent" id="mainContent">
      <div data-title="融资申请信息" class="modelBox">
        <text-title index="01" label="融资申请信息"></text-title>
        <form-info
          :componentList="financingApplicationFrom"
          :model="financingApplicationData"
          :showbor="true"
          :count="financCount"
        />
      </div>
      <div data-title="标的资产" class="modelBox">
        <text-title index="02" label="标的资产"></text-title>
        <div class="head">
          <title-center :rules="false" name="资产信息"></title-center>
        </div>
        <base-table
          :columns="assetInfoColumn"
          :tableAttrs="{
            data: assetInfoData,
            stripe: true
          }"
          :isCaculateHeight="false"
          :showPage="false"
        >
          <template slot="code" slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
          <template slot="easNo" slot-scope="scope">
            <div class="lookPaymentOrder" @click="lookPaymentOrder(scope.row)">
              {{ scope.row.easNo }}
            </div>
          </template>
        </base-table>
        <!-- <div class="head">
          <title-center :rules="false" name="项目信息"></title-center>
        </div>
        <base-table
          :columns="projectInfoColumn"
          :tableAttrs="{
            data: projectInfoData,
            stripe: true
          }"
          :isCaculateHeight="false"
          :showPage="false"
        >
          <template slot="code" slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </base-table> -->
      </div>
      <!-- <div data-title="备案条款" class="modelBox">
        <text-title index="03" label="备案条款"></text-title>
        <base-table
          :columns="filingTermsColumn"
          :tableAttrs="{
            data: filingTermsData,
            stripe: true
          }"
          :isCaculateHeight="false"
          :showPage="false"
        >
          <template slot="code" slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </base-table>
      </div> -->
      <div data-title="经营及合作情况" class="modelBox">
        <text-title index="03" label="经营及合作情况"></text-title>
        <div class="tips">{{ tipsDescription }}</div>
      </div>
      <div data-title="附件" class="modelBox">
        <text-title index="04" label="附件"></text-title>
        <base-table
          :columns="accessoriesColumn"
          :tableAttrs="{
            data: accessoriesData,
            stripe: true
          }"
          :isCaculateHeight="false"
          :showPage="false"
        >
          <template slot="fileListDetailVOS" slot-scope="scope">
            <div v-for="item in scope.row.fileListDetailVOS" :key="item.fileId">
              <div class="filelist">
                <span>{{ item.fileName }}</span>
                <icon-button
                  content="预览"
                  icon="iconfont iconyulan"
                  @click="previewShow(item)"
                />
              </div>
            </div>
          </template>
          <template slot="code" slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
          <!-- 文件名称 -->
        </base-table>
      </div>
      <div data-title="业务经理意见" class="modelBox">
        <text-title index="05" label="业务经理意见"></text-title>
        <div class="tips">{{ reviewOpinion }}</div>
        <!-- <form-info
          :componentList="arrangementForm"
          :model="expectDueDiligence"
          :showbor="true"
          :count="count"
        /> -->
      </div>
      <div data-title="文件生成与下载" class="modelBox">
        <text-title index="06" label="文件生成与下载"></text-title>
        <div class="row">
          <div class="left">
            <el-input
              :disabled="true"
              v-model="fileMakeData.fileName"
            ></el-input>
          </div>
          <icon-button
            content="预览"
            class="fl-16"
            icon="iconfont iconyulan"
            @click="previewShow(fileMakeData)"
            v-if="fileMakeData.fileId && fileMakeData.fileId !== '0'"
          />
        </div>
      </div>
    </div>
    <div class="footer" v-if="!isBusinessId">
      <base-button label="关 闭" type="default" @click="close"></base-button>
    </div>
    <!-- 文件预览 -->
    <pre-view
      :fileId="fileData.fileId"
      :isOpen="true"
      :fileType="fileData.fileFileSuffix"
      :count="fileCount"
    />
    <!-- 付款单详情信息 -->
    <payment-order-details-dialog
      :visible.sync="visibleOrderDetails"
      v-if="visibleOrderDetails"
      :keyId="keyId"
    ></payment-order-details-dialog>
  </div>
</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'

import TextTitle from '@/components/packages/text-title/text-title.vue'
import Backtitle from '../../components/backtitle.vue'
import baseButton from '@/components/common/button/base-button/base-button.vue'
import PaymentOrderDetailsDialog from '@/pages/assets-manage/payment-order-details/components/payment-order-details-dialog.vue'
// import BaseForm from '@/components/common/base-form/base-form'
// import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
import FormInfo from '@/components/packages/form-info/form-info-new.vue'
import {
  financingApplicationConfig,
  assetInfoTable,
  projectInfoTable,
  filingTermsTable,
  accessoriesTable,
  arrangementFormData
} from '../utils/detial-config'
import baseTable from '@/components/common/table/base-table/base-table.vue'
import Storage from '@/utils/storage'
import { getDictLists, getDict } from '@/filters/fromDict'
import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { formatDate } from '@/utils/auth/common'
import TitleCenter from '@/pages/business/components/title-center.vue'

export default {
  components: {
    TextTitle,
    Backtitle,
    baseButton,
    FormInfo,
    baseTable,
    IconButton,
    PreView,
    TitleCenter,
    PaymentOrderDetailsDialog
  },
  props: {
    baseData: Object,
    isBusinessId: String
  },
  data () {
    return {
      fileCount: 0,
      fileData: {},
      financingApplicationData: {}, // 融资申请信息
      assetInfoData: [], // 标的资产信息
      projectInfoData: [],
      filingTermsData: [], // 备案条款
      accessoriesData: [], // 附件
      arrangementData: {}, // 尽调安排
      count: 0,
      tipsDescription: '', // 提示
      expectDueDiligence: {}, // 尽调数据
      fileMakeData: {}, // 文件制作名称
      passArr: [],
      gysId: Storage.getLocal('admittanceInfo').gysId,
      financCount: 0,
      visibleOrderDetails: false,
      keyId: '',
      reviewOpinion: ''
    }
  },
  created () {
    this.businessId = this.$route.query.businessId || this.isBusinessId
    this.getAssetsInfo()
    this.getRecordTableData()
    this.getEnclosureTableData()
  },
  watch: {
    baseData: {
      handler: function (val) {
        if (val) {
          // 申请信息
          this.financingApplicationData = val
          this.financingApplicationData.supplierTypeName = val.supplierType
            ? getDict('SUPPLIER_TYPE', val.supplierType)
            : '--'
          this.financingApplicationData.areaName = this.financingApplicationData
            .serviceRate.areaId
            ? getDict('BUSINESS_AREA', val.areaId)
            : '--'
          this.financingApplicationData.capitalAndProductName = val.capitalSideName
            ? val.capitalSideName + '-' + val.capitalSideProductName
            : '--'
          this.financingApplicationData.serviceRate = val.serviceRate.toString()
          this.financingApplicationData.changeOrNot = (val.changeOrNot === '1' ? '是' : (val.changeOrNot === '0' ? '否' : val.changeOrNot))
          this.financCount++
          // 提示
          this.tipsDescription = val?.tips
          this.reviewOpinion = val?.reviewOpinion
          const {
            expectDueDiligenceAddress,
            expectDueDiligenceDate,
            expectMeetingTime
          } = { ...val }
          // 尽调安排
          this.expectDueDiligence = {
            expectDueDiligenceAddress,
            expectDueDiligenceDateName: expectDueDiligenceDate
              ? formatDate(expectDueDiligenceDate, 'YY-MM-DD')
              : '',
            expectMeetingTimeName: expectMeetingTime
              ? formatDate(expectMeetingTime, 'YY-MM-DD')
              : ''
          }
          this.count++
          // 生成文件
          this.fileMakeData = val.fileListInfo ? val.fileListInfo : {}
        }
      },
      deep: true
    }
  },
  computed: {
    // 合作类型字典
    cooperationType () {
      return getDictLists('COMPANY_COOPERATION_TYPE').map(item => {
        return { value: item.dictId, label: item.dictName }
      })
    },
    financingApplicationFrom () {
      return financingApplicationConfig(this)
    },
    assetInfoColumn () {
      return assetInfoTable(this)
    },
    projectInfoColumn () {
      return projectInfoTable(this)
    },
    filingTermsColumn () {
      return filingTermsTable(this)
    },
    accessoriesColumn () {
      return accessoriesTable(this)
    },
    arrangementForm () {
      return arrangementFormData(this)
    }
  },
  methods: {
    // 获取标的资产
    getAssetsInfo () {
      financeAdmittanceApi
        .assetsInfo({ businessId: this.businessId })
        .then(res => {
          this.assetInfoData = res.data.finContractInfoList
            ? res.data.finContractInfoList
            : []
          // if (this.assetInfoData.length > 0) {
          //   this.assetInfoData.forEach((item) => {
          //     if (item.easNo) {
          //       item.paymentOrder = item.easNo.split(',')
          //     }
          //   })
          // }
          this.projectInfoData = res.data.finProjectInfoList
            ? res.data.finProjectInfoList
            : []
          this.projectInfoData = this.projectInfoData?.map(item => {
            return { projectAddress: item.province + item.city, ...item }
          })
        })
    },
    // 获取备案条款
    getRecordTableData () {
      financeAdmittanceApi
        .getAdmittanceFinling({ businessId: this.businessId })
        .then(res => {
          if (res.data) {
            this.filingTermsData = res.data.map(item => {
              return {
                ...item,
                approveResultName: item.approveResult === '0' ? '是' : '否'
              }
            })
          }
        })
    },
    // 初始化数据
    getEnclosureTableData () {
      financeAdmittanceApi
        .getAdmittanceFile({ businessId: this.businessId })
        .then(res => {
          // this.accessoriesData = res.data
          this.accessoriesData = res.data.map(item => {
            return {
              ...item,
              isProvideName: item.isProvide === '0' ? '否' : '是'
            }
          })
          console.log(this.accessoriesData, 'this.accessoriesData')
        })
    },
    // 预览
    previewShow (data) {
      if (data && data.fileId) {
        this.$set(this.fileData, 'fileId', data.fileId)
        this.$set(
          this.fileData,
          'fileFileSuffix',
          data.fileName.substr(data.fileName.lastIndexOf('.') + 1, 4)
        )
        this.fileCount++
      }
    },
    close () {
      this.$router.back()
    },
    // 查看付款单
    lookPaymentOrder (row) {
      this.keyId = row.payApplyKeyId
      this.visibleOrderDetails = true
    }
  }
}
</script>
<style lang="scss" scoped>
.accessMake {
  padding: 0px 10px;
  height: 100%;
  background: #f9f9f6;
  .mainContent {
    background: #fff;
    height: calc(100% - 105px);
    overflow-y: scroll;
    .lookPaymentOrder {
      color: #0d96f1;
      cursor: pointer;
    }
  }
  .footer {
    text-align: center;
    margin: 0px 0 10px;
    padding: 14px 0;
    box-shadow: 0px 0px 13px 2px rgba(#4a7cf0, 0.2);
    background: #ffffff;
  }
}
.head {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  height: 30px;
}
.tips {
  font-size: 16px;
  margin: 10px;
  width: 100%;
  height: 200px;
}
.filelist {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row {
  display: flex;
  padding: 20px;
  .left {
    width: 200px;
  }
}
/deep/ .el-radio-group {
  margin-top: 10px;
}
</style>
